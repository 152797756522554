import { CLOUDINARY_ASSET_FIELDS, IMAGE_FIELDS } from './snippets';

const references = `//groq
  'type': item->_type,
  item->_type == 'product' => {
    'title': select(
      title != undefined => title,
      item->shortTitle != undefined && item->shortTitle != '' => item->shortTitle,
      item->title
    ),
    "image": item->images[]{
      _type == "image" => {
        ${IMAGE_FIELDS}
      },
      _type == "cloudinaryAsset" => {
        ${CLOUDINARY_ASSET_FIELDS}
      },
    } [defaultImage == true][0],
    'description': select(
      description != undefined => description,
      item->shortDescription
    ),
    'slug': select(
      slug != undefined => slug.current,
      item->slug.current
    )
  },
  item->_type == 'category' => {
    'title': select(
      title != undefined => title,
      item->title
    ),
    'description': select(
      description != undefined => description,
      item->metaDescription
    ),
    'slug': select(
      slug != undefined => slug.current,
      item->slug.current
    )
  },
  item->_type == 'blogPost' => {
    'title': select(
      title != undefined => title,
      item->title
    ),
    'description': select(
      description != undefined => description,
      item->author._type == 'reference' => author->{name}.name,
      item->author
    ),
    'slug': select(
      slug != undefined => slug.current,
      item->slug.current
    )
  },
  item->_type == 'page' => {
    'title': select(
      title != undefined => title,
      item->title
    ),
    'description': select(
      description != undefined => description,
      item->metaDescription
    ),
    'slug': select(
      slug != undefined => slug.current,
      item->slug.current
    )
  },
  item->_type == 'ambassador' => {
    'title': select(
      title != undefined => title,
      item->name
    ),
    'description': select(
      description != undefined => description,
      item->shortDescription
    ),
    'slug': select(
      slug != undefined => slug.current,
      item->slug.current
    ) 
  },
  item->_type == 'author' => {
    'title': select(
      title != undefined => title,
      item->name
    ),
    'description': select(
      description != undefined => description,
      item->singleLineDescription
    ),
    'slug': select(
      slug != undefined => slug.current,
      item->slug.current
    ) 
  },
  item->_type == 'subMenuItem' => {
    'ref': item._ref,
    title,
    description,
    slug
  }
`;

export const subMenuQuery = `//groq
*[_type == 'subMenuItem' && !(_id in path('drafts.**'))] 
{
  description,
  extendedDesc,
  title,
  'type': _type,
  'id': _id,
  internalLink{
      'title': select(title == undefined => reference->title, title),
      'slug': select(reference != undefined => reference->slug.current, slug.current),
    },
  additionalResources {
    title,
    items[] {
      'slug': select(reference != undefined => reference->slug.current, slug.current),
      'title': select(title == undefined => reference->title, title)
    }
  },
  items[] {
    _type == 'internalRef' => {
      ${references}
    },
    _type == 'internalLink' => {
        'slug': select(reference != undefined => reference->slug.current, slug.current),
        'title': select(title == undefined => reference->title, title),
        description,
      },
  }
}`;

export const rootMenuQuery = `//groq
*[_type == 'rootMenuItem' && !(_id in path('drafts.**'))] 
  {
    title,
    'type': _type,
    description,
    extendedDesc,
    sortOrder,
    internalLink {
      'title': select(title == undefined => reference->title, title),
      'slug': select(reference != undefined => reference->slug.current, slug.current)
    },
    menuBanner->{
      title,
      'image': image {
        _type == "image" => {
          ${IMAGE_FIELDS}
        },
        _type == "cloudinaryAsset" => {
          ${CLOUDINARY_ASSET_FIELDS}
        },
      },
      links[] {
        'slug': internalLink->slug.current,
        'fallback': link
      },
      startsAt,
      expiresAt
    },
    additionalResources {
      title,
      items[] {
        'slug': select(reference != undefined => reference->slug.current, slug.current),
        'title': select(title == undefined => reference->title, title)
      }
    },
    items[] {
      _type == 'internalLink' => {
        'slug': select(reference != undefined => reference->slug.current, slug.current),
        'title': select(title == undefined => reference->title, title),
        description,
      },
      _type == 'submenuReference' => {
        ${references}
      },
    }
  } | order(sortOrder asc)`;

export const whatsTrendingQuery = `//groq
  *[_type == "marketingCopy"] {
    "trendingItems": trendingItems[] {
      'image': image {
         ${IMAGE_FIELDS}
      },
      'slug': select(internalLink != undefined => internalLink->slug.current, fallbackUrl.current)
    }
  }
`;
