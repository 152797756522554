import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { GiCheckMark } from 'react-icons/gi';

import { Button, ButtonType } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { Modal } from '@components/Modal/Modal';

export function SuccessAlert({
  title = 'Success',
  message,
  show,
  setShow,
  children,
  buttonClassName,
}: {
  title?: string;
  message?: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
  buttonClassName?: string;
}): ReactElement {
  const close = () => setShow(false);

  return (
    <Modal
      show={show}
      setShow={setShow}
      className="w-[90vw] max-w-[450px] dark:bg-black"
    >
      <section
        data-testid="success-alert"
        className="flex flex-col items-center justify-center gap-2"
      >
        <GiCheckMark size="80px" color="theme(colors.green.DEFAULT)" />
        <h2 className="mt-4">{title}</h2>
        <p className="my-4 flex items-center justify-evenly gap-4">
          {children ? children : message}
        </p>
        <Button
          type={ButtonType.button}
          onClick={close}
          buttonStyle={ButtonStyle.cta}
          className={buttonClassName ?? ''}
        >
          Ok
        </Button>
      </section>
    </Modal>
  );
}
