import {
  forwardRef,
  MouseEvent,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { Button } from '@components/Button';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { mergeStyles } from '@lib/styles';

export const scrollToPosition = (
  element: HTMLElement | Window,
  left: number,
  top: number = 0
) => {
  element.scrollTo({
    top,
    left,
    behavior: 'smooth',
  });
};

interface NavigationBarProps {
  children: React.ReactNode;
  id?: string;
  testid?: string;
  scrollTo?: string;
}

export const NavigationBar = forwardRef<HTMLDivElement, NavigationBarProps>(
  ({ children, id, testid, scrollTo }, ref) => {
    const {
      headerScrollClass: [headerScrollClass],
    } = useContext<UIContextInterface>(UIContext);

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    // Type guard to check if ref is MutableRefObject
    const getRef = () => {
      if (ref && typeof ref !== 'function') {
        return ref as MutableRefObject<HTMLDivElement | null>;
      }
      return null;
    };

    const getMenuSize = () => {
      const navRef = getRef();
      return navRef?.current?.scrollWidth || 0;
    };

    const updateArrowVisibility = () => {
      const nav = getRef()?.current;
      if (!nav) return;

      const totalWidth = getMenuSize();
      const visibleWidth = nav.offsetWidth;
      const scrollPosition = nav.scrollLeft;

      setShowLeftArrow(scrollPosition > 0);
      setShowRightArrow(totalWidth - visibleWidth - scrollPosition > 10);
    };

    const handleNavButtonClick = (direction: 'left' | 'right') => {
      const nav = getRef()?.current;
      if (!nav) return;

      const visibleWidth = nav.offsetWidth;
      const offset = direction === 'left' ? -visibleWidth : visibleWidth;
      scrollToPosition(nav, nav.scrollLeft + offset);
    };

    // handle horizontal grab and drag in browser
    const handleMouseDown = (e: MouseEvent) => {
      const navRef = getRef();

      if (!navRef?.current) return;

      setIsDragging(true);
      setStartX(e.pageX - navRef.current.offsetLeft);
      setScrollLeft(navRef.current.scrollLeft);
    };

    const handleMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      const navRef = getRef();

      if (!isDragging || !navRef?.current) return;

      const x = e.pageX - navRef.current.offsetLeft;
      const walk = x - startX; // Distance dragged
      navRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false);

    // used to auto scroll the nav bar to the correct section as the user scrolls
    // this helps when a nav bar section is hidden and a user has scrolled to that section
    // offset is for the arrows on the side
    const scrollNavToSection = (sectionId: string) => {
      const navRef = getRef()?.current;
      if (!navRef) return;

      const sectionLink = document.querySelector(`[data-id="${sectionId}"]`);

      if (sectionLink) {
        const { left: linkLeft } = sectionLink.getBoundingClientRect();
        const { left: navLeft } = navRef.getBoundingClientRect();
        const scrollOffset = linkLeft - navLeft + navRef.scrollLeft - 30;
        scrollToPosition(navRef, scrollOffset);
      }
    };

    useEffect(() => {
      const nav = getRef()?.current;
      nav?.addEventListener('scroll', () => updateArrowVisibility());
      window.addEventListener('resize', updateArrowVisibility);

      return () => {
        nav?.removeEventListener('scroll', () => updateArrowVisibility());
        window.removeEventListener('resize', updateArrowVisibility);
      };
    }, []);

    useEffect(() => {
      if (scrollTo) scrollNavToSection(scrollTo);
    }, [scrollTo]);

    return (
      <div
        id={id}
        data-testid={testid}
        className={mergeStyles(
          headerScrollClass === 'hide' ? '-top-1' : `top-[122px]`,
          'full-bleed sticky z-[998] min-h-[30px] border-t-2 border-white transition-all duration-200 ease-in-out dark:border-black'
        )}
      >
        <div
          ref={ref}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          className="horizontal-scroll overflow-x-auto whitespace-nowrap bg-grey-mid text-center text-sm dark:bg-grey-dark"
        >
          {children}
        </div>
        {showLeftArrow && (
          <Button
            tabIndex={-1}
            aria-label="Slide left"
            className="absolute bottom-0 left-0 top-0 h-12 w-6 bg-grey-mid text-grey-dark hover:bg-grey-light dark:bg-grey-dark dark:text-white
          hover:dark:bg-black hover:dark:text-white"
            onClick={() => handleNavButtonClick('left')}
            icon={<FiChevronLeft size="1.5rem" />}
          />
        )}
        {showRightArrow && (
          <Button
            tabIndex={-1}
            aria-label="Slide right"
            className="absolute bottom-0 right-0 top-0 h-12 w-6 bg-grey-mid text-grey-dark hover:bg-grey-light dark:bg-grey-dark dark:text-white
          hover:dark:bg-black hover:dark:text-white"
            onClick={() => handleNavButtonClick('right')}
            icon={<FiChevronRight size="1.5rem" />}
          />
        )}
      </div>
    );
  }
);

NavigationBar.displayName = 'NavigationBar';
