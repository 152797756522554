import React, { useEffect, useState } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Container } from '@components/Container';
import { useGaCookie } from '@hooks/useGaCookie';

import { DynamicExternal } from '../Footer/DynamicExternal';
import { DynamicInternal } from '../Footer/DynamicInternal';

import { CustomerSentiment } from './CustomerSentiment';
import { StaticFooter } from './StaticFooter';

export function Footer() {
  const gaCookie = useGaCookie('yotpo-footer-ab-jan-2025');

  const [showYotpo, setShowYotpo] = useState(true);

  useEffect(() => {
    // require use effect to prevent hydration error
    if (gaCookie[1]) setShowYotpo(+gaCookie[1] === 0);
  }, [gaCookie]);

  return (
    <Container>
      <BulkErrorBoundary location="Footer">
        <CustomerSentiment />
        {showYotpo && <DynamicExternal />}
        <DynamicInternal />
        <StaticFooter />
      </BulkErrorBoundary>
    </Container>
  );
}
