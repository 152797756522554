import { useEffect, useRef, useState } from 'react';

import { NavigationBar, scrollToPosition } from './NavigationBar';

export function ProductNav({
  links,
}: {
  links: { title: string; id: string }[];
}) {
  const [manualSelection, setmanualSelection] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(links[0].id); // default to first section

  const productNavRef = useRef<HTMLDivElement>(null);

  // handle vertical scroll to section of nav bar click
  const scrollToSection = (sectionId: string) => {
    setmanualSelection(true);
    setSelected(sectionId);

    const section = document.getElementById(sectionId);
    if (section) {
      const yPosition =
        section.getBoundingClientRect().top + window.scrollY - 30;

      scrollToPosition(window, 0, yPosition);
    }
  };

  useEffect(() => {
    // observe each section to be able to track which section the user is currently viewing
    // this is used to auto highlight the correct nav bar section as a user scrolls
    const observer = new IntersectionObserver(
      (entries) => {
        // find first entry so it's the top most visible section
        const visibleSection = entries.find((e) => e.isIntersecting)?.target.id;
        if (visibleSection && !manualSelection) setSelected(visibleSection);
      },
      { threshold: 0.1 } // trigger once .1 of section is visible
    );

    links.forEach((link) => {
      const section = document.getElementById(link.id);
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [links, manualSelection]);

  useEffect(() => {
    // need a timeout to allow time for auto scroll animation to complete without triggering the observer.
    // when multiple oberserved sections are in viewport, unexpected behaviours can happen with observer and manual selections
    // 1000 is for large section changes, takes time to scroll
    if (manualSelection) {
      const timeout = setTimeout(() => setmanualSelection(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [manualSelection]);

  return (
    <NavigationBar
      id="productNavBar"
      ref={productNavRef}
      testid="product-nav-bar"
      scrollTo={`${encodeURIComponent(selected)}-link`}
    >
      {links.map((link: { title: string; id: string }, i) => (
        <button
          type="button"
          onClick={() => scrollToSection(link.id)}
          data-id={`${link.id}-link`}
          key={`productnav-link-${i}`}
          aria-label={link.title}
          className={`${selected === link.id ? 'border-b-4 text-grey-darkest dark:text-grey-mid' : ''} xl:min-w-auto inline-block h-12 min-w-[100px] cursor-pointer border-green px-5 py-1.5 font-secondary text-lg font-bold uppercase leading-none tracking-wider hover:text-green`}
        >
          {link.title}
        </button>
      ))}
    </NavigationBar>
  );
}
