import * as Dialog from '@radix-ui/react-dialog';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { BiX } from 'react-icons/bi';
import { twJoin } from 'tailwind-merge';

import { LoginForm } from '@components/LoginForm';
import { contentStyles, Modal, overlayStyles } from '@components/Modal/Modal';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';

export function LoginModalUncontrolled({
  trigger,
  title = (
    <>
      Login or <TextLink href="/create-account">create an account</TextLink>
    </>
  ),
}: {
  trigger: ReactElement;
  title?: ReactElement;
}): ReactElement {
  return (
    <>
      <Dialog.Root>
        <TooltipWrapper text="Login">
          <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
        </TooltipWrapper>
        <Dialog.Portal>
          <Dialog.Overlay className={overlayStyles} />
          <Dialog.Content
            className={twJoin([
              contentStyles,
              'data-[state=open]:animate-contentShow max-h-[85vh] w-[90vw] max-w-[450px] rounded-md p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none',
            ])}
          >
            <div className="w-full">
              <Dialog.Title className="m-0 mb-6 text-lg font-medium text-grey-dark dark:text-white">
                {title}
              </Dialog.Title>
              <div className="my-4">
                <LoginForm />
              </div>
            </div>
            <Dialog.Close asChild>
              <button
                type="button"
                aria-label="Close"
                className="absolute right-2.5 top-2.5 border-none bg-transparent font-secondary text-4xl text-black hover:cursor-pointer hover:text-orange hover:no-underline focus:cursor-pointer focus:no-underline dark:text-white dark:hover:cursor-pointer dark:hover:text-orange dark:hover:no-underline dark:focus:cursor-pointer dark:focus:no-underline print:hidden"
              >
                <BiX />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}

/**
 * Login modal component.
 * @param modalTitle is the h2 to show at the top of the modal.
 * @param modalIntent is a string to show after "please log in or create an account ..." i.e. you could say "to finally meet that new years resolution" or something
 */
export function LoginModal({
  showModal,
  setShowModal,
  modalTitle,
  modalIntent,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  modalIntent?: string;
}): ReactElement {
  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="data-[state=open]:animate-contentShow max-h-[85vh] w-[90vw] max-w-[450px] rounded-md p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
    >
      <section className="max-w-[500px]">
        <h2 className="py-4">{modalTitle}</h2>
        {modalIntent && (
          <Paragraph>
            Log in or{' '}
            <TextLink href="/create-account">create an account</TextLink>{' '}
            {modalIntent}
          </Paragraph>
        )}
        <LoginForm
          onLogin={() => {
            setShowModal(false);
          }}
        />
      </section>
    </Modal>
  );
}
